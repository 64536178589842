import { format, ILabeledDropdownOption } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

export interface IReviewCountResources {
    pageReviewCountText: string;
}

export interface IReviewCountProps {
    resources: IReviewCountResources;
    currentPage: number;
    reviewsCount: number;
    reviewsShownOnEachPage: number;
}

export const ReviewCount: React.FC<IReviewCountProps> = (props: IReviewCountProps) => {
    const {currentPage, resources, reviewsCount, reviewsShownOnEachPage} = props;

    return (
        <span aria-live='polite' className='sr-only'>
            {format(
                resources.pageReviewCountText,
                _getReviewStartNumber(currentPage, reviewsShownOnEachPage),
                _getReviewEndNumber(currentPage, reviewsCount, reviewsShownOnEachPage),
                reviewsCount
            )}
        </span>
    );
};

export interface IPageControlResources {
    previousButtonText: string;
    nextButtonText: string;
    pageReviewAriaLabel: string;
}

export interface IPageControlsProps {
    resources: IPageControlResources;
    currentPage: number;
    reviewsCount: number;
    reviewsShownOnEachPage: number;
    selectedSortByOption: ILabeledDropdownOption;
    selectedFilterByOption: ILabeledDropdownOption;
    updateReviews(skipItems: number, selectedSortByOption: ILabeledDropdownOption, selectedFilterByOption: ILabeledDropdownOption, currentPage: number, isFilterApplied: boolean): Promise<void>;
}

export const PageControls: React.FC<IPageControlsProps> = (props: IPageControlsProps) => {
    const {currentPage, resources, reviewsCount, reviewsShownOnEachPage} = props;
    const previousPageClick = () => {return _getPreviousPageData(props); };
    const nextPageClick = () => {return _getNextPageData(props); };

    return (
        <div className='ms-reviews-list__page-controls'>
            {_hasPreviousPage(currentPage) && (
                <button
                    onClick={previousPageClick}
                    className={'ms-reviews-list__previous'}
                    aria-label={_paginationAriaLabel(resources.pageReviewAriaLabel, currentPage, reviewsCount, reviewsShownOnEachPage,  false)}
                >
                    {resources.previousButtonText}
                </button>
            )}
            {_hasNextPage(currentPage, reviewsCount, reviewsShownOnEachPage) && (
                <button
                    onClick={nextPageClick}
                    className={'ms-reviews-list__next'}
                    aria-label={_paginationAriaLabel(resources.pageReviewAriaLabel, currentPage, reviewsCount, reviewsShownOnEachPage,  true)}
                >
                    {resources.nextButtonText}
                </button>
            )}
        </div>
    );
};

const _hasPreviousPage = (currentPage: number): boolean => {
    return currentPage > 1;
};

const _hasNextPage = (currentPage: number, reviewsCount: number, reviewsShownOnEachPage: number): boolean => {
    return _getPagesCount(reviewsCount, reviewsShownOnEachPage) > currentPage;
};

const _getReviewsShown = (reviewsShownOnEachPage: number) => {
    return Math.min(25, reviewsShownOnEachPage);
};

const _getPagesCount = (reviewsCount: number, reviewsShownOnEachPage: number): number => {
    return Math.ceil(reviewsCount / _getReviewsShown(reviewsShownOnEachPage));
};

const _paginationAriaLabel = (pageReviewAriaLabel: string, currentPage: number, reviewsCount: number, reviewsShownOnEachPage: number, isNextPage: boolean, page?: number): string => {
    const pageModifier = isNextPage ? 1: -1;
    return format(
                pageReviewAriaLabel,
                _getReviewStartNumber(currentPage, reviewsShownOnEachPage, currentPage + pageModifier),
                _getReviewEndNumber(currentPage, reviewsCount, reviewsShownOnEachPage, currentPage + pageModifier),
                reviewsCount
            );
};

const _getReviewStartNumber = (currentPage: number,  reviewsShownOnEachPage: number, page?: number): number => {
    return ((page || currentPage) - 1) * _getReviewsShown(reviewsShownOnEachPage) + 1;
};

const _getReviewEndNumber = (currentPage: number, reviewsCount: number, reviewsShownOnEachPage: number, page?: number): number => {
    const pageUsed = page || currentPage;
    const pageReviewCount = pageUsed * _getReviewsShown(reviewsShownOnEachPage);
    return pageReviewCount < reviewsCount ? pageReviewCount : reviewsCount;
};

const _getPreviousPageData = async (props: IPageControlsProps) => {
    await props.updateReviews(
        _getReviewStartNumber(props.currentPage, props.reviewsShownOnEachPage) - 1 - _getReviewsShown(props.reviewsShownOnEachPage),
        props.selectedSortByOption,
        props.selectedFilterByOption,
        props.currentPage - 1,
        false
    );
};

const _getNextPageData = async (props: IPageControlsProps) => {

    await props.updateReviews(
        _getReviewEndNumber(props.currentPage, props.reviewsCount, props.reviewsShownOnEachPage),
        props.selectedSortByOption,
        props.selectedFilterByOption,
        props.currentPage + 1,
        false
    );
};