import { OrderBy } from '../actions/inputs/get-product-reviews-input';

export interface IRnrKeyValuePair {
    key: string;
    value: string;
}

export interface IRnrOrderByKeyValuePair {
    key: OrderBy;
    value: string;
}

export function setFocusOnElement(id: string): void {
    const element = document.getElementById(id);
    if (!!element) {
        element.focus();
    }
}

export const TOGGLE_REVIEW_MODAL_BUTTON_ID: string = 'reviewproductid';
export const TOGGLE_EDIT_MODAL_BUTTON_ID: string = 'ms-write-review__edit';
export const TOGGLE_EDIT_MODAL_BUTTON_CARD_ID: string = 'ms-reviews-list__edit';