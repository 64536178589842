import { CacheType, IActionInput } from '@msdyn365-commerce/core';

export const enum ReviewConcernCategory {
    OffensiveContent = '1',
    ReviewResponse = '7',
    SpamAdvertising = '8',
    Profanity = '9'
}

/**
 * ReportReviewConcern Input Action
 */
export class ReportReviewConcernInput implements IActionInput {
    public reviewId: string;

    public tenantId: string;

    public locale: string;

    public serviceEndpoint: string;

    public concernCategory: string;

    public authToken: string;

    constructor(
        reviewId: string,
        tenantId: string,
        locale: string,
        concernCategory: string,
        authToken: string,
        serviceEndpoint: string
    ) {
        this.reviewId = reviewId;
        this.tenantId = tenantId;
        this.locale = locale;
        this.concernCategory = concernCategory;
        this.authToken = authToken;
        this.serviceEndpoint = serviceEndpoint;
    }

    public getCacheKey = () => `ReportReviewConcern`;
    public getCacheObjectType = () => 'ReportReviewConcern';
    public dataCacheType = (): CacheType => 'none';
}
