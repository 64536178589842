import {
    getDeliveryOptionsForSelectedVariant,
    GetDeliveryOptionsForSelectedVariantInput,
    getDimensionsForSelectedVariant,
    GetDimensionsForSelectedVariantInput,
    getPriceForSelectedVariant,
    getProductAvailabilitiesForSelectedVariant,
    getSelectedVariant,
    IProductInventoryInformation,
    PriceForSelectedVariantInput,
    ProductAvailabilitiesForSelectedVariantInput,
    SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { IModuleProps, INodeProps } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import { ProductDeliveryOptions, ProductDimensionValue, ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import classnames from 'classnames';
import * as React from 'react';
import { IBuyboxData, IBuyboxProps } from '../..';
import { IBuyboxResources } from './buybox.props.autogenerated';
import {
    getBuyboxAddToCart,
    getBuyboxFindInStore,
    getBuyBoxInventoryLabel,
    getBuyboxProductAddToWishlist,
    getBuyboxProductConfigure,
    getBuyboxProductDescription,
    getBuyboxProductPrice,
    getBuyboxProductQuantity,
    getBuyboxProductRating,
    getBuyboxProductTitle,
    IBuyboxAddToCartViewProps,
    IBuyboxAddToWishlistViewProps,
    IBuyboxFindInStoreViewProps,
    IBuyboxProductConfigureViewProps,
    IBuyboxProductQuantityViewProps } from './components';

export declare type IBuyboxErrorHost = 'ADDTOCART' | 'FINDINSTORE' | 'WISHLIST';

export interface IErrorState {
    errorHost?: IBuyboxErrorHost;

    configureErrors: { [configureId: string]: string | undefined };
    quantityError?: string;
    otherError?: string;
}

export interface IBuyboxCallbacks {
    updateQuantity(newQuantity: number): void;
    updateErrorState(newErrorState: IErrorState): void;
    updateSelectedProduct(
        selectedProduct: Promise<SimpleProduct | null>,
        newInventory: IProductInventoryInformation | undefined,
        newPrice: ProductPrice | undefined,
        newDeliveryOptions: ProductDeliveryOptions | undefined): void;
    getDropdownName(dimensionType: number, resources: IBuyboxResources): string;
    dimensionSelectedAsync(selectedDimensionId: number, selectedDimensionValueId: string): Promise<void>;
    changeModalOpen(isModalOpen: boolean): void;
}

export interface IBuyboxState {
    quantity: number;
    errorState: IErrorState;
    selectedDimensions: { [id: number]: string | undefined };
    selectedProduct?: Promise<SimpleProduct | null>;
    productAvailableQuantity?: IProductInventoryInformation;
    productPrice?: ProductPrice;
    productDeliveryOptions?: ProductDeliveryOptions;
    modalOpen?: boolean;
}

export interface IBuyboxViewProps extends IBuyboxProps<IBuyboxData> {
    state: IBuyboxState;

    ModuleProps: IModuleProps;
    ProductInfoContainerProps: INodeProps;
    MediaGalleryContainerProps: INodeProps;

    callbacks: IBuyboxCallbacks;

    mediaGallery?: React.ReactNode;

    title?: React.ReactNode;
    description?: React.ReactNode;
    rating?: React.ReactNode;
    price?: React.ReactNode;
    addToWishlist?: IBuyboxAddToWishlistViewProps;

    addToCart: IBuyboxAddToCartViewProps;
    findInStore?: IBuyboxFindInStoreViewProps;
    quantity?: IBuyboxProductQuantityViewProps;
    configure?: IBuyboxProductConfigureViewProps;
    inventoryLabel?: React.ReactNode;
}

/**
 * Buybox Module
 */
class Buybox extends React.PureComponent<IBuyboxProps<IBuyboxData>, IBuyboxState> {
    private buyboxCallbacks: IBuyboxCallbacks = {
        updateQuantity: (newQuantity: number): void => {
            const errorState = {...this.state.errorState};
            errorState.quantityError = undefined;
            errorState.otherError = undefined;

            this.setState({quantity: newQuantity, errorState: errorState});
        },
        updateErrorState: (newErrorState: IErrorState): void => {
            this.setState({errorState: newErrorState});
        },
        updateSelectedProduct: (
            newSelectedProduct: Promise<SimpleProduct | null>,
            newInventory: IProductInventoryInformation | undefined,
            newPrice: ProductPrice | undefined,
            newDeliveryOptions: ProductDeliveryOptions | undefined
        ): void => {
            this.setState({
                selectedProduct: newSelectedProduct,
                productAvailableQuantity: newInventory,
                productPrice: newPrice,
                productDeliveryOptions: newDeliveryOptions});
        },
        dimensionSelectedAsync: (selectedDimensionId: number, selectedDimensionValueId: string): Promise<void> => {
            return this._dimensionSelected(selectedDimensionId, selectedDimensionValueId);
        },
        getDropdownName:  (dimensionType: number, resources: IBuyboxResources): string => {
            return this._getDropdownName(dimensionType, resources);
        },
        changeModalOpen: (isModalOpen: boolean): void => {
            this.setState({modalOpen: isModalOpen});
        }
    };

    constructor(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState) {
        super(props);
        this.state = {
            errorState: {
                configureErrors: {}
            },
            quantity: 1,
            selectedProduct: undefined,
            selectedDimensions: {},
            productPrice: undefined,
            productDeliveryOptions: undefined,

            modalOpen: false,
        };
    }

    public render(): JSX.Element | null {
        const {
            slots: {
                mediaGallery,
            },
            data: {
                product: { result: product }
            },
            config: { className = '' }
        } = this.props;

        if (!product) {
            this.props.context.telemetry.error('Product content is empty, module wont render');
            return null;
        }

        const viewProps: IBuyboxViewProps = {
            ...(this.props as IBuyboxProps<IBuyboxData>),
            state: this.state,
            mediaGallery: mediaGallery && mediaGallery.length > 0 ? mediaGallery[0] : undefined,
            ModuleProps: {
                moduleProps: this.props,
                className: classnames('ms-buybox', className)
            },
            ProductInfoContainerProps: {
                className: 'ms-buybox__content'
            },
            MediaGalleryContainerProps: {
                className: 'ms-buybox__media-gallery'
            },
            callbacks: this.buyboxCallbacks,
            title: getBuyboxProductTitle(this.props),
            description: getBuyboxProductDescription(this.props),
            configure: getBuyboxProductConfigure(this.props, this.state, this.buyboxCallbacks),
            findInStore: getBuyboxFindInStore(this.props, this.state, this.buyboxCallbacks),
            price: getBuyboxProductPrice(this.props),
            addToCart: getBuyboxAddToCart(this.props, this.state, this.buyboxCallbacks),
            addToWishlist: getBuyboxProductAddToWishlist(this.props, this.state, this.buyboxCallbacks),
            rating: !this.props.context.app.config.hideRating && getBuyboxProductRating(this.props),
            quantity: getBuyboxProductQuantity(this.props, this.state, this.buyboxCallbacks),
            inventoryLabel: getBuyBoxInventoryLabel(this.props)
        };

        return this.props.renderView(viewProps) as React.ReactElement;
    }

    // tslint:disable-next-line:max-func-body-length
    private _dimensionSelected = async (selectedDimensionId: number, selectedDimensionValue?: string): Promise<void> => {
        const {
            data: {
                product: {result: product },
                productDimensions: {result: productDimensions },
            },
            context: {
                actionContext,
                request: {
                    apiSettings: {
                        channelId
                    }
                }
            }
        } = this.props;
        const
        {
            selectedDimensions
        } = this.state;

        if (!product || !productDimensions) {
            return;
        }
        // Step 1: Update state to indicate which dimensions are selected
        const newSelectedDimensions: { [id: number]: string | undefined } = {...selectedDimensions};
        newSelectedDimensions[selectedDimensionId] = selectedDimensionValue;
        this.setState({selectedDimensions: newSelectedDimensions});
        // Step 2: Clear any errors indicating the dimension wasn't selected
        if (this.state.errorState.configureErrors[selectedDimensionId]) {
            const errorState = {...this.state.errorState};
            errorState.configureErrors[selectedDimensionId] = undefined;

            this.setState({errorState: errorState});
        }
        // Step 3, Build the actually selected dimensions, prioritizing the information in state
        // over the information in data
        const mappedDimensions = productDimensions.map(dimension => {
            return {
                DimensionTypeValue: dimension.DimensionTypeValue,
                DimensionValue: this._updateDimensionValue(dimension, newSelectedDimensions[dimension.DimensionTypeValue]) || dimension.DimensionValue,
                ExtensionProperties: dimension.ExtensionProperties
            };
        }).filter(dimension => {
            return dimension && dimension.DimensionValue;
        });
        // Step 4. Use these dimensions hydrate the product. Wrap this in a promise
        // so that places like add to cart can await it
        const selectedProduct = new Promise<SimpleProduct | null>(async (resolve, reject) => {
            const newProduct = (await getSelectedVariant(
                new SelectedVariantInput(
                    product.MasterProductId ? product.MasterProductId : product.RecordId,
                    channelId,
                    mappedDimensions
                ),
                actionContext
            ));
            if (newProduct) {
                await getDimensionsForSelectedVariant(
                    new GetDimensionsForSelectedVariantInput(
                        newProduct.MasterProductId ? newProduct.MasterProductId : newProduct.RecordId,
                        channelId,
                        mappedDimensions
                    ),
                    actionContext
                );
            }

            resolve(newProduct);
        });
        this.setState({selectedProduct: selectedProduct});
        const variantProduct = await selectedProduct;

        if(variantProduct) {
            // Step 5. Use these dimensions hydrate the inventory. Wrap this in a promise
            // so that places like add to cart can await it
            const newAvailableQuantity = await getProductAvailabilitiesForSelectedVariant(
                new ProductAvailabilitiesForSelectedVariantInput(
                    variantProduct.RecordId,
                    channelId
                ),
                actionContext
            );

            if(newAvailableQuantity && newAvailableQuantity.length) {
                this.setState({productAvailableQuantity: newAvailableQuantity[0]});
            } else {
                this.setState({productAvailableQuantity: undefined});
            }

            // Step 6. Use these dimensions hydrate the product price.
            const newPrice = await getPriceForSelectedVariant(
                new PriceForSelectedVariantInput(
                    variantProduct.RecordId,
                    channelId
                ),
                actionContext
            );

            if(newPrice) {
                this.setState({productPrice: newPrice});
            }

            // Step 7. Use these dimensions hydrate the product delivery options.
            const newDeliveryOptions = await getDeliveryOptionsForSelectedVariant(
                new GetDeliveryOptionsForSelectedVariantInput(
                    variantProduct.RecordId,
                    channelId
                ),
                actionContext
            );

            if(newDeliveryOptions) {
                this.setState({productDeliveryOptions: newDeliveryOptions});
            }
        }
    }

    private _updateDimensionValue = (productDimensionFull: ProductDimensionFull, newValueId: string | undefined): ProductDimensionValue | undefined => {
        if (newValueId && productDimensionFull.DimensionValues) {
            return productDimensionFull.DimensionValues.find(dimension => dimension.RecordId === +newValueId);
        }

        return undefined;
    };

    private _getDropdownName = (dimensionType: number, resources: IBuyboxResources): string => {
        switch (dimensionType) {
            case 1: // ProductDimensionType.Color
                return resources.productDimensionTypeColor;
            case 2: // ProductDimensionType.Configuration
                return resources.productDimensionTypeConfiguration;
            case 3: // ProductDimensionType.Size
                return resources.productDimensionTypeSize;
            case 4: // ProductDimensionType.Style
                return resources.productDimensionTypeStyle;
            default:
                return '';
        }
    };
}

export default Buybox;
