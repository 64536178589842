import { CacheType, IActionInput } from '@msdyn365-commerce/core';
import { IUserReviewData } from './submit-user-review-input-data';

/**
 * SubmitUserReview Input Action
 */
export class SubmitUserReviewInput implements IActionInput {
    /**
     * The Id representing the product to get the ratings for.
     */
    public productId?: string;

    /**
     * The ID representing the tenant.
     */
    public tenantId: string;

    /**
     * The calling organization's channel id.
     */
    public channelId: string;

    /**
     * The locale for which to get the ratings from.
     */
    public locale: string;

    /**
     * The user's review data
     */
    public userReviewData: IUserReviewData;

    /**
     * The user's authorization token
     */
    public authorization: string;

    /**
     * The ratings and reviews service endpoint
     */
    public serviceEndpoint: string;

    constructor(
        productId: string | undefined,
        tenantId: string,
        channelId: string,
        locale: string,
        userReviewData: IUserReviewData,
        authorization: string,
        serviceEndpoint: string
    ) {
        this.productId = productId;
        this.tenantId = tenantId;
        this.channelId = channelId;
        this.locale = locale;
        this.userReviewData = userReviewData;
        this.authorization = authorization;
        this.serviceEndpoint = serviceEndpoint;
    }

    public getCacheKey = () => 'SubmitUserReview';
    public getCacheObjectType = () => 'SubmitUserReview';
    public dataCacheType = (): CacheType => 'none';
}
