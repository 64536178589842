import {
    format,
    INodeProps,
    Progress
} from '@msdyn365-commerce-modules/utilities';
import { RatingsSummary } from '@msdyn365-commerce/commerce-entities';
import React from 'react';

export interface IHistogramItemsProps {
    ratingsSummary: RatingsSummary;
    resources: IHistogramItemsResources;
    locale: string;
    onClick(stars: number): void;
}

export interface IHistogramItemsResources {
    barItemAriaLabel: string;
}

export interface IHistogramItemViewProps {
    starLabel: React.ReactElement;
    bar: React.ReactElement;
    percentage: React.ReactElement;
    buttonProps: INodeProps;
}

/**
 * CartLineItems component
 */

export const HistogramItems = (props: IHistogramItemsProps): IHistogramItemViewProps[]  => {
    const {ratingsSummary} = props;
    const totalRatingsCount: number = ratingsSummary.totalRatingsCount || 0;
    return [
        renderItem(props, 5, ratingsSummary.star5Count, totalRatingsCount),
        renderItem(props, 4, ratingsSummary.star4Count, totalRatingsCount),
        renderItem(props, 3, ratingsSummary.star3Count, totalRatingsCount),
        renderItem(props, 2, ratingsSummary.star2Count, totalRatingsCount),
        renderItem(props, 1, ratingsSummary.star1Count, totalRatingsCount),
    ];
};

const renderItem = (props: IHistogramItemsProps,  stars: number, count?: number, totalCount?: number): IHistogramItemViewProps => {
    count = count || 0;
    totalCount = totalCount || 0;
    const percentage = totalCount === 0 ? 0 : count / totalCount;
    const formattedPercent = new Intl.NumberFormat(props.locale, { style: 'percent' }).format(percentage);
    const ariaLabel = format(props.resources.barItemAriaLabel, stars, formattedPercent);

    return {
        starLabel: <span className='ms-ratings-histogram__star'>{stars}</span>,
        percentage: <span className='ms-ratings-histogram__percentage'>{formattedPercent}</span>,
        bar: <Progress value={percentage * 100} max={100} ariaLabel={ariaLabel} className='ms-ratings-histogram__bar'/>,
        buttonProps: {tag: 'button', className: 'ms-ratings-histogram__item', onClick: () => {props.onClick(stars);}}
    };
};

export const AverageRatingNumber: React.FC<{summary: RatingsSummary}> = (props: {summary: RatingsSummary}) => {
    return <p className='ms-ratings-histogram__average'>{props.summary.averageRating} / 5</p>;
};

export const TotalReviews: React.FC<{summary: RatingsSummary}> = (props: {summary: RatingsSummary}) => {
    return <p className='ms-ratings-histogram__total'>{props.summary.totalRatingsCount}</p>;
};