import { observable } from 'mobx';

/**
 * State information related to what is needed to succesfully perform a autosuggest
 */
export class SearchState {
    @observable public searchText?: string;
    @observable public defaultBasicSearchUrl?: string;
    @observable public searchQueryStringParameter?: string;
}
