import { Button, format, INodeProps } from '@msdyn365-commerce-modules/utilities';
import { Review } from '@msdyn365-commerce/commerce-entities';
import {RatingComponent} from '@msdyn365-commerce/components';
import { IAny, ICoreContext, IGeneric} from '@msdyn365-commerce/core';
import React from 'react';
import { TOGGLE_EDIT_MODAL_BUTTON_CARD_ID } from '../../../common/rnrInterface';

export interface IReviewCardResources {
    wasReviewHelpfulText: string;
    yesButtonText: string;
    noButtonText: string;
    editReviewCardText: string;
    reportedText: string;
    reportConcernText: string;
    reviewRatingNarratorText: string;
}

export interface IReviewCardProps {
    review: Review;
    resources: IReviewCardResources;
    context:  ICoreContext<IGeneric<IAny>>;
    isUserReview?: boolean;
    parentId: string;
    parentType: string;
    reportedReviews: string[];
    returnRef: React.RefObject<HTMLButtonElement> | undefined;
    reportedReviewId: string;
    votedReviews: {reviewId: string; approved: boolean}[];
    toggleWriteReviewModal?(): void;
    toggleReportReviewModal(reviewId: string): void;
    updateReviewHelpfulness(reviewId: string, isHelpful: boolean): void;
}

export interface IReviewCardViewProps {
    cardProps: INodeProps;
    name: React.ReactElement;
    rating: React.ReactElement;
    date: React.ReactElement;
    reviewTitle: React.ReactElement;
    reviewText: React.ReactElement;
    ratingHelpfulLabel: React.ReactElement;
    like: React.ReactElement;
    dislike: React.ReactElement;
    edit: React.ReactNode;
    report: React.ReactNode;
    responseName: React.ReactNode;
    responseDate: React.ReactNode;
    responseText: React.ReactNode;
    cardBodyProps: INodeProps;
    responseProps: INodeProps;
    reviewProps: INodeProps;
    controlsProps: INodeProps;
    headerProps: INodeProps;
}

/**
 * Review card component
 */

export const ReviewCard = (props: IReviewCardProps):IReviewCardViewProps  => {
    const className = props.isUserReview ? 'ms-reviews-list__card ms-reviews-list__user-review' : 'ms-reviews-list__card';
    return {
        cardProps: { className: className, key: props.review.reviewId },
        name: <p className='ms-reviews-list__name'>{props.review.userName}</p>,
        rating: rating(props),
        date: <p className='ms-reviews-list__date'>{renderDate(props, props.review.submittedDateTime)}</p>,
        reviewTitle: <h4 className='ms-reviews-list__title'>{props.review.title}</h4>,
        reviewText: <p className='ms-reviews-list__text'>{props.review.reviewText}</p>,
        ratingHelpfulLabel: <p className='ms-reviews-list__helpful'>{props.resources.wasReviewHelpfulText}</p>,
        like: voteHelpfulButton(props, true),
        dislike: voteHelpfulButton(props, false),
        edit: editButton(props),
        report: reportToggle(props),
        responseName: props.review.userResponse ? <p className='ms-reviews-list__response-name'>{props.review.userResponse.responderName}</p> : undefined,
        responseText: props.review.userResponse ? <p className='ms-reviews-list__response-text'>{props.review.userResponse.responseText}</p> : undefined,
        responseDate: props.review.userResponse ? <p className='ms-reviews-list__response-date'>{renderDate(props, props.review.userResponse.submittedDateTime)}</p> : undefined,
        cardBodyProps: { className: 'ms-reviews-list__card-body' },
        responseProps: { className: 'ms-reviews-list__response' },
        reviewProps: { className: 'ms-reviews-list__review' },
        controlsProps: { className: 'ms-reviews-list__card-controls' },
        headerProps: { className: 'ms-reviews-list__card-header' }
    };
};

const rating = (props: IReviewCardProps) => {
    return(
        <RatingComponent
            readOnly={true}
            avgRating={props.review.rating}
            ariaLabel={format(props.resources.reviewRatingNarratorText || '', props.review.rating, '5')}
            context={props.context}
            className='ms-reviews-list__rating'
            id={props.parentId}
            typeName={props.parentType}
            data={{}}
        />
    );
};

const isVotedFor = (props: IReviewCardProps, isHelpful: boolean): boolean => {
    for (let i = 0; i < props.votedReviews.length; i++) {
        if(props.votedReviews[i].reviewId === props.review.reviewId) {
            return props.votedReviews[i].approved === isHelpful;
        }
    }

    return false;
};

const voteHelpfulButton = (props: IReviewCardProps, isHelpful: boolean) => {
    const isVoted = isVotedFor(props, isHelpful);
    const isDisabled = props.isUserReview || isVoted ? 'disabled' : '';
    let className = isHelpful ? 'ms-reviews-list__like' : 'ms-reviews-list__dislike';
    className = `${className} ${isDisabled}`;
    const label = isHelpful ? props.resources.yesButtonText : props.resources.noButtonText;
    let voteCount = (isHelpful ? props.review.helpfulPositive : props.review.helpfulNegative) || 0;
    voteCount = isVoted ? voteCount + 1 : voteCount;
    const title = `(${voteCount})`;
    const user = props.context.request.user;
    const onClickMethod = () => {props.updateReviewHelpfulness(props.review.reviewId!, isHelpful);};
    const onClick= isDisabled  ? undefined : onClickMethod;

    if (user.isAuthenticated) {
        return <Button aria-label={label} className={className} title={title} onClick={onClick}>{title}</Button>;
    }
    return <Button href={user.signInUrl} aria-label={label} className={className} title={title} tag='a'>{title}</Button>;
};

const editButton = (props: IReviewCardProps) => {
    if (props.isUserReview) {
        return <Button id={TOGGLE_EDIT_MODAL_BUTTON_CARD_ID} className='ms-reviews-list__edit' onClick={props.toggleWriteReviewModal}>{props.resources.editReviewCardText}</Button>;
    }

    return undefined;
};

const renderDate = (props: IReviewCardProps, date?: Date): string | Date => {
    if (!date) {
        return '';
    }

    try {
        return new Intl.DateTimeFormat(props.context.request.locale, {month: 'long', day: 'numeric', year: 'numeric'}).format(new Date(date));
    } catch {
        return date;
    }
};

const reportToggle = (props: IReviewCardProps) => {
    const user = props.context.request.user;
    if (props.isUserReview) {
        return undefined;
    } else if (!user.isAuthenticated) {
        return <Button href={user.signInUrl} className='ms-reviews-list__report' tag='a'> {props.resources.reportConcernText}</Button>;
    }

    if (props.reportedReviews.indexOf(props.review.reviewId!) > -1) {
        return <p className='ms-reviews-list__reported'>{props.resources.reportedText}</p>;
    }

    const ref = props.review.reviewId === props.reportedReviewId ? props.returnRef : undefined;
    const onClick = () => {props.toggleReportReviewModal(props.review.reviewId!);};
    return <Button className='ms-reviews-list__report' onClick={onClick} innerRef={ref}>{props.resources.reportConcernText}</Button>;

};