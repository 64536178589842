import { CacheType, IActionInput } from '@msdyn365-commerce/core';

/**
 * SubmitHelpfulnessVote Input Action
 */
export class SubmitHelpfulnessVoteInput implements IActionInput {
    public reviewId: string;

    public tenantId: string;

    public locale: string;

    public serviceEndpoint: string;

    public isHelpful: boolean;

    public authToken: string;

    constructor(
        reviewId: string,
        tenantId: string,
        locale: string,
        isHelpful: boolean,
        authToken: string,
        serviceEndpoint: string
    ) {
        this.reviewId = reviewId;
        this.tenantId = tenantId;
        this.locale = locale;
        this.isHelpful = isHelpful;
        this.authToken = authToken;
        this.serviceEndpoint = serviceEndpoint;
    }

    public getCacheKey = () => `SubmitHelpfulnessVote`;
    public getCacheObjectType = () => 'SubmitHelpfulnessVote';
    public dataCacheType = (): CacheType => 'none';
}
